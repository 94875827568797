<template>
  <div>
    <Loading v-if="$global.state.loading" />
    <div v-if="!$global.state.loading">
      <v-row>
        <v-col cols="12" md="3" v-for="(item, index) in items" :key="index">
          <v-card id="card">
            <v-container fluid class="text-center">
              <v-progress-circular
                :rotate="360"
                :size="130"
                :width="10"
                :value="item.percentage"
                :color="item.color"
                v-if="item.type == 'percentage'"
              >
                <h2>
                  <v-chip>
                    <h6 class="grey--text">( {{ item.value }} )</h6>
                  </v-chip>
                  <br />
                  <v-chip dark :color="item.color"
                    ><h2>{{ item.percentage }} %</h2></v-chip
                  >
                </h2>
              </v-progress-circular>

              <v-chip label v-if="item.type == 'count'">
                <h2 class="grey--text">{{ item.value }}</h2>
              </v-chip>

              <h2 v-if="item.type == 'money'">
                {{ $service.formatCurrency(item.value) }}
              </h2>
              <!-- :class="item.type == 'percentage' ? 'mb-2 ml-2' : ''" -->
              <h3 class="secondary--text mt-4">{{ item.title }}</h3>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "عدد المبيعات",
          value: 0,
          type: "count",
          color: "",
        },
        {
          title: "عدد العملاء",
          value: 0,
          type: "count",
          color: "",
        },
        {
          title: "عدد المناطق",
          value: 0,
          type: "count",
          color: "",
        },
        {
          title: "عدد المستخدمين",
          value: 0,
          type: "count",
          color: "",
        },

        {
          title: "نسبة المبيعات المكتملة",
          value: 0,
          percentage: 0,
          type: "percentage",
          color: "teal",
        },
        {
          title: "نسبة المبيعات الغير مكتملة",
          value: 0,
          percentage: 0,
          type: "percentage",
          color: "orange",
        },
        {
          title: "نسبة المبيعات الغير مكتملة",
          value: 0,
          percentage: 0,
          type: "percentage",
          color: "orange",
        },
        {
          title: "نسبة المبيعات الغير مكتملة",
          value: 0,
          percentage: 0,
          type: "percentage",
          color: "orange",
        },

        {
          title: "مبلغ الأقساط بالدولار",
          value: 0,
          type: "money",
          color: "",
        },
        {
          title: "مبلغ الأقساط بالدينار",
          value: 0,
          type: "money",
          color: "",
        },
      ],
    };
  },

  created() {
    this.getDashboard();
  },

  methods: {
    async getDashboard() {
      this.$global.state.loading = true;
      try {
        const res = await this.$http.get(`Dashboards`);
        //count type
        this.items[0].value = res.data.result.salesCount; // عدد المبيعات
        this.items[1].value = res.data.result.clientsCount; // عدد العملاء
        this.items[2].value = res.data.result.regionsCount; // عدد المناطق
        this.items[3].value = res.data.result.usersCount; // عدد المستخدمين

        //percentage type
        this.items[4].value = res.data.result.finishedSalesCount; // عدد المبيعات المكتملة
        this.items[4].percentage =
          (res.data.result.finishedSalesCount / res.data.result.salesCount) *
          100; // نسبة المبيعات المكتملة

        this.items[5].value = res.data.result.unfinishedSalesCount; // عدد المبيعات  الغير المكتملة
        this.items[5].percentage =
          (res.data.result.unfinishedSalesCount / res.data.result.salesCount) *
          100; // نسبة المبيعات الغير مكتملة

        //money type
        this.items[8].value = res.data.result.totalCapitalSales; // مبلغ الأقساط بالدولار
        this.items[9].value = res.data.result.totalAmountPaidInDinarSales; // مبلغ الأقساط بالدينار

        console.log(res);
      } catch (err) {
        console.log(err);
        this.$service.faild(err.response.data.message);
      } finally {
        this.$global.state.loading = false;
      }
    },
  },
};
</script>
